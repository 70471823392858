.ReplacementPartDialog-body {
    margin: 0 !important;
    width: 100% !important;
    padding: 4px 0 !important;
}
.ReplacementPartDialog-body .MuiSvgIcon-fontSizeLarge {
    color: rgba(0, 0, 0, 0.54);
}
.ReplacementPartDialog .MuiDialogTitle-root {
    position: absolute;
    right: 0;
    padding: 25px 24px !important;
}
.ReplacementPartDialog .MuiDialogActions-root {
    padding-left: 14px !important;
    padding-right: 14px !important;
}
#ldar-replacement-column {
    padding-top: 10px;
}
#ldar-replacement-column .MuiGrid-item {
    height: 69px;
}

.header-margin {
    margin-top: 50px !important;
    margin-bottom: 25px !important;
}

.Airquality-table-row tr td {
    border-bottom: none !important;
}

.no-bottom-border {
    border-bottom: none !important;
}

.content-class-header {
    min-width: 65px;
}

.auto-complete label.Mui-disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}
.auto-complete input.Mui-disabled {
    color: rgba(0, 0, 0, 0.87) !important;
}
.auto-complete .MuiAutocomplete-input:first-of-type {
    padding-top: 9px !important;
    padding-bottom: 1px !important;
}

.text-fess-headline {
    font-weight: bold !important;
    margin-bottom: 1rem;
}

.text-fess-medium {
    margin-bottom: 1rem;
}

.text-fess-subheadline {
    font-size: 1.25rem !important;
    margin-bottom: 1rem;
}

.text-fess {
    font-size: 1.75rem !important;
    font-weight: bold !important;
}

.showcase {
    background-color: #f2f3f5;
}

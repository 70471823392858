.sideBar {
  position: relative;
}

.sideBar .sideBar-left {
  position: fixed;
  border-right: 1px solid #e0e0e0;
  height: 100%;
  top: 0;
  width: 50px;
}

.sideBar .sideBar-left img {
  width: 48px;
  height: 48px;
}

.sideBar .sideBar-right {
  margin-left: 50px;
}

.sideBar .Content-nav-rotate {
  transform: rotate(180deg);
}

@media (max-width: 650.95px) {
  .sideBar .sideBar-left {
      position: absolute;
      height: 50px;
      border-right: none;
  }

  .sideBar .sideBar-right {
      margin-left: 0;
  }
}

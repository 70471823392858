.popper {
    top: 7px !important;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 
                0px 8px 10px 1px rgba(0, 0, 0, 0.14), 
                0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: white;
}

.popper .user-panel {
    min-width: 400px;
    padding: 0 11px;
}

.popper .user-panel .user-panel-welcome {
    padding: 14px 0 11px;
}

.popper .user-panel .user-panel-welcome span {
    margin-left: 8px;
}

.popper .user-panel .dialog_button {
    color: #0091dc;
    text-transform: unset;
    font: Normal 16px/24px Meta Pro !important;
}

.popper .user-panel button {
    width: 100%;
    justify-content: left;
}

.popper .user-panel button:focus {
    outline: 0;
}

.popper .user-panel .panel_aligment {
    border-top: 1px solid #e7e7e7;
    padding: 5px 0 5px;
}

.popper .MuiAutocomplete-listbox li:nth-child(1),
.popper .MuiAutocomplete-listbox li:nth-child(2) {
    font-weight: bold !important;
}

.replacement-parts-table table > thead > tr > th:first-of-type, .replacement-parts-table table > thead > tr th:last-child {
    width: 3rem;
}
.replacement-parts-table table > tbody > tr > td {
    overflow-wrap: anywhere;
}
.MaterialSearchDialog .MuiDialog-paper {
    height: 70%;
    width: 60%;
    max-width: 60%;
}
.MaterialSearchDialog .MuiDialog-paper thead > tr > th:first-of-type {
    width: 15%;
}
.MaterialSearchDialog .MuiDialog-paper thead > tr > th:last-child {
    width: 5%;
}
.ReplacementPartDialog_body button .MuiSvgIcon-root {
    font-size: 1.9rem !important;
    color: rgba(0, 0, 0, 0.54);
}

.ImageViewerDialog {
    z-index: 0 !important;
}

.ImageViewerDialog .MuiDialog-paper {
    min-width: 400px;
    max-width: 800px;
}

.ImageViewerDialog .MuiDialogTitle-root .MuiTypography-root {
    margin-top: 3px;
}

.ImageViewerDialog .MuiDialogContent-root .image-viewer-file-picker {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.ImageViewerDialog .MuiDialogContent-root .file-select {
    display: flex;
}

.ImageViewerDialog .MuiDialogContent-root .file-select input {
    display: none;
}

.ImageViewerDialog .MuiDialogContent-root .file-select > div {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.ImageViewerDialog .MuiDialogContent-root .file-select > div label {
    margin-bottom: 0;
}

.ImageViewerDialog .MuiDialogContent-root .file-select > div label span {
    text-transform: none;
}

.ImageViewerDialog .MuiDialogContent-root .file-select > div:last-child > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ImageViewer .ReactGridGallery_tile {
    background: white !important;
}

.ImageViewer .ReactGridGallery_tile .ReactGridGallery_tile-icon-bar div {
    visibility: visible !important;
}

.ImageViewer .ReactGridGallery_tile:hover .ReactGridGallery_tile-overlay {
    height: 40px !important;
    background: rgba(0, 0, 0, 0.5) !important;
}

.ImageViewer .ReactGridGallery_tile:hover .ReactGridGallery_tile-icon-bar {
    position: absolute;
    z-index: 1;
}

.ImageViewer .ReactGridGallery_tile .ReactGridGallery_tile-viewport {
    margin: auto;
}

.ImageViewer .ReactGridGallery_tile .ReactGridGallery_tile-description {
    max-width: 160px;
    text-overflow: ellipsis;
    margin: auto !important;
}

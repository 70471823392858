.projects .projectMenu-body {
    padding-bottom: 42px;
}
.projectView-content {
    position: relative;
    min-height: calc(100vh - 515px);
}
.projectView-content .service-type img {
    object-fit: contain;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.projectView-content .service-type .quality {
    width: 20px;
}
.projectView-content a {
    color: #0091dc;
}
.projectView-content .projectView-left {
    margin-top: 15px;
    border-right: 1px solid rgba(224, 224, 224, 1);
}
.projectView-content .projectView-left .MuiButtonBase-root {
    justify-content: left !important;
}
.projectView-content .projectView-left .projectView-button {
    border-left: 1px solid rgba(224, 224, 224, 1);
    border-radius: 0;
    padding: 13px 13px 13px 30px;
    width: 100%;
    text-transform: unset;
}
.projectView-content .projectView-left .projectView-button:focus {
    outline: none;
}
.projectView-content .projectView-left .borderChange {
    border-left: 4px solid #0091dc;
    color: #0091dc !important;
}
.text-second {
    padding-top: 55px;
    font: Normal 1rem/1.5rem Meta Pro !important;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.Table_error_container {
    margin-top: 30px;
    color: #555;
    user-select: none;
}
.Table_error_container svg {
    font-size: 90px;
    color: #dbdbdb;
}
.Table_error_container h1, .Table_error_container h2, .Table_error_container h3 {
    font-weight: normal !important;
    font: Normal 1rem/1.5rem Meta Pro !important;
    margin: 0;
}
.Table_error_container h1 {
    font-size: 32px !important;
}
.Table_error_container h2 {
    margin-top: 10px;
    font-size: 24px !important;
}
.Table_error_container .error_svg_circle {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Table_error_container .error_svg_circle svg {
    font-size: 60px;
    color: white !important;
}
.word-break-all {
    word-break: break-all;
}
@media (max-width: 960px) {
    .text-second {
        padding-top: 0;
        text-align: center;
   }
    .projectView-left {
        border-right: 0px !important;
   }
}

.ContentWrapper {
    flex-wrap: nowrap !important;
    flex-grow: 1;
}

.ContentWrapper .ContentWrapper-navigation {
    position: sticky;
    top: 75px;
    left: 0;
    height: 100%;
    min-width: 200px;
    max-width: 100%;
    background-color: white;
    transition: 3s;
}

.ContentWrapper .ContentWrapper-navigation h6 {
    margin-bottom: 10px;
}

.ContentWrapper .ContentWrapper-navigation .nav-border {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.ContentWrapper .ContentWrapper-navigation .nav-border .MuiButtonBase-root {
    justify-content: flex-start;
}

.ContentWrapper .ContentWrapper-navigation .nav-border button {
    width: 100%;
    padding: 13px 13px 13px 30px;
    text-transform: none;
    text-align: left;
}

.ContentWrapper .ContentWrapper-navigation .nav-border .active-heading {
    border-left: 4px solid #0091dc;
    color: #0091dc;
}

.ContentWrapper .ContentWrapper-content {
    border-left: 1px solid #e0e0e0;
    padding-right: 1px;
    padding-left: 20px;
    overflow: hidden;
}

@media (max-width: 960px) {
    .ContentWrapper .ContentWrapper-navigation {
        display: none;
    }

    .ContentWrapper .ContentWrapper-content {
        border-left: none;
        padding-left: 0px;
        max-width: 100%;
        flex-basis: 100%;
    }
}

.air-quality-measurement .pb-0 {
    padding-bottom: 0px;
}

.aqm-explnationimage {
    text-align: center;
}

.aqm-explnationimage > img {
    max-width: 100%;
    max-height: 100%;
}

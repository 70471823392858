.IndividualDryer .compressor-selected-error {
    height: 0;
    margin: 0;
}

.IndividualDryer .dryer-single-select > div > div {
    margin-top: 11px;
}

.IndividualDryer .dryer-assigned-compressors > div > table > thead > tr:first-of-type > th:first-of-type {
    width: 2rem;
}

.general-information .MuiAutocomplete-input {
    padding-bottom: 1px !important;
}

.general-information .table-body-row > td:first-of-type {
    white-space: nowrap;
}

.general-information #GI-work-time > div:nth-child(2) {
    padding-top: 3rem !important;
}

.general-information #GI-work-time > div:nth-child(3) .MuiTypography-root {
    margin-top: 0;
}

.general-information #GI-work-time > div:nth-last-child(2) {
    padding-top: 0.6rem !important;
}

.general-information #GI-work-time > div:last-child {
    padding-top: 0.5rem !important;
}

.general-information .validation-formhelper-error-textfield > p {
    position: relative;
    top: -10px;
    height: 0;
    margin: 0;
}

.compressed-air-generation table {
    border-collapse: collapse;
}

.compressed-air-generation table td {
    position: relative;
}

.compressed-air-generation table td.mark-red:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid #D50000;
}

.compressed-air-generation .specific-power-of-system {
    height: 320px;
}

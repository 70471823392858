.inquiry {
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 60px;
    font: Normal 16px/24px Meta Pro;
}

.inquiry textarea {
    height: 200px !important;
    min-height: 100% !important;
    width: 100%;
}

.inquiry .info-box {
    background-color: rgb(242, 243, 245);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
    border-radius: 0.5em;
}

.inquiry .info-box hr {
    border-top: 1px solid #e0e0e0;
}

.inquiry .info-box .info-box-content h6 {
    margin-bottom: 15px;
}

.inquiry .info-box .info-box-content .info-box-text {
    padding: 0 20px;
}

.inquiry .info-box img {
    object-fit: cover;
    width: 100%;
    border-radius: 0 0 0.5em 0.5em;
}

.inquiry > .MuiGrid-root .customer-information > div:first-of-type {
    margin-bottom: 2rem;
}

.inquiry > .MuiGrid-root .customer-information > div:nth-child(n+1) {
    margin-bottom: 1.5rem;
}

.inquiry > .MuiGrid-root .service-selection {
    width: 100%;
}

.inquiry > .MuiGrid-root .service-selection > div:nth-of-type(2) {
    padding-top: 24px;
}

.inquiry > .MuiGrid-root .service-selection label {
    margin-bottom: 0.8rem;
}

.inquiry > .MuiGrid-root .compressed-air-padding {
    padding-left: 30px;
}

.inquiry > .MuiGrid-root .spacer {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.inquiry .table-head-row > th {
    font: Bold 14px/10px Meta Pro;
    letter-spacing: 0;
    color: #333333;
    opacity: 1;
}

.inquiry .table-body-row > td {
    border-bottom: none;
    font: Normal 16px/60px Meta Pro;
}

.inquiry .policy-link {
    padding-top: 8px;
}

.inquiry .followUp-link {
    padding-top: 8px;
}

.inquiry .ambient-climatic-conditions-table .MuiTableRow-root > td:first-of-type {
    vertical-align: bottom;
}

.inquiry #check-service-error {
    padding-bottom: 0;
    padding-top: 0;
}

.inquiry #check-service-error > p {
    position: relative;
    height: 0;
    margin: 0;
}

.top-bar-container {
    background: #f2f3f5 0% 0% no-repeat padding-box;
}

.top-bar-container .top-bar {
    position: relative;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.hidden {
    display: none;
}

.info-icon-festo {
    margin-top: 10px;
    color: #0091dc;
}

.admin-panel label {
    margin-bottom: 0;
}
.admin-panel .flex {
    flex-wrap: nowrap;
}
.admin-panel .flex .admin-navigation-container {
    background-color: whitesmoke;
}
.admin-panel .flex .admin-navigation-container .admin-navigation {
    position: sticky;
    top: 50px;
    left: 0;
    min-width: 300px;
    max-width: 300px;
    overflow-y: auto;
}
.admin-panel .flex .admin-navigation-container .admin-navigation .MuiTypography-body1 {
    margin-left: 16px;
}
.admin-panel .flex .admin-navigation-container .admin-navigation .admin-button {
    border-radius: 0;
    width: 100%;
    text-transform: unset;
    padding: 3px 3px 3px 16px !important;
}
.admin-panel .flex .admin-navigation-container .admin-navigation .admin-button .MuiListItemIcon-root {
    min-width: 0 !important;
}
.admin-panel .flex .admin-navigation-container .admin-navigation .admin-button svg {
    font-size: 20px;
    color: #0091dc;
}
.admin-panel .flex .admin-navigation-container .admin-navigation .admin-button .MuiButton-label {
    justify-content: flex-start !important;
}
.admin-panel .flex .admin-navigation-container .admin-navigation .admin-button:focus {
    outline: none;
}
.admin-panel .flex .admin-content {
    width: calc(100% - 300px);
    min-height: calc(100vh - 90px);
    padding: 0 20px;
    border-left: 1px solid #f3f3f3;
}
.admin-panel .top-bar {
    background-color: white;
}
.admin-panel .country {
    margin-top: 0;
    margin-bottom: 30px;
}
.admin-panel .country ol {
    left: 280px;
}
.admin-panel .mobileView {
    display: none;
}
.admin-panel .nav-active {
    display: block !important;
}
.admin-panel .borderChange {
    border-left: 4px solid #0091dc !important;
}
.admin-panel .mobileView-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
}
.admin-panel .authenticate-message {
    left: 0;
    bottom: 0;
}
.admin-panel .user-management .prewrap {
    white-space: pre-wrap;
}
@media (max-width: 960px) {
    .admin-panel .flex .admin-content {
        border: none !important;
        width: 100%;
   }
    .admin-navigation {
        display: none;
        z-index: 9;
   }
    .mobileView {
        display: block !important;
        padding: 0 !important;
        position: absolute !important;
        top: 95px;
        left: 15px;
        z-index: 8;
   }
}
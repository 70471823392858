.footer-companyInitials {
    font: Normal 12px/20px "Meta Pro";
    color: #656565;
    height: 40px;
}

.footer-companyInitials a {
    font: Normal 12px/20px "Meta Pro";
    color: #656565;
    margin-right: 25px;
}

.footer-companyInitials a:nth-last-child(1) {
    margin: 0;
}

@media (max-width: 960px) {
    .footer-companyInitials {
        flex-direction: column-reverse !important;
        text-align: center;
        height: 100% !important;
    }

    .footer-companyInitials p {
        padding-top: 17px;
    }
}

.chart-label {
    margin-top: 20px;
}

.side-borders {
    border-right: 1px solid rgba(224, 224, 224, 1);
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.left-border {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.right-border {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.list-seperator-grid {
    padding-top: 5px;
}

.LDAR-LeakageHigh {
    color: #D50000 !important;
}

.LDAR-LeakageLow {
    color: #99a9b4 !important;
}

.LDAR-LeakageOptimization {
    color: #82ca9d !important;
}

.LDAR-LeakageMedium {
    color: #ffe135 !important;
}

span:has(#LDAR-Export-Excel) {
    display: grid;
    align-content: center;
}

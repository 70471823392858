@font-face {
    font-family: "festo-icons";
    src: url("./fonts/iconfont/festo-icons_eot.eot");
    src: url("./fonts/iconfont/festo-icons_woff.woff") format("woff"), 
         url("./fonts/iconfont/festo-icons_ttf.ttf") format("truetype");
}

@font-face {
    font-family: "Meta Pro";
    src: url("./fonts/MetaPro-Regular.woff") format("woff");
}

p {
    margin: 0;
    padding: 0;
}

a {
    cursor: pointer;
    text-decoration: none !important;
}

a:hover {
    color: #0091dc;
}

.hidden {
    display: none;
}

body {
    font-family: "Meta Pro";
    margin: 0;
    padding: 0;
}

textarea {
    width: calc(100% - 6px);
}

.MuiTableCell-root {
    padding: 8px;
}

.MuiContainer-root {
    max-width: 1800px;
    padding: 0 42px;
}

.mark-red {
    color: #D50000 !important;
}

.text-color-red {
    color: #D50000 !important;
}

td > a {
    cursor: pointer;
}

.breadcrumb-container {
    margin-bottom: 3rem;
    height: 41px;
}

.breadcrumb-container nav {
    height: 20px;
}

.breadcrumb-container ol {
    padding-left: 42px;
}

.single-table-page {
    margin: 0 !important;
}

.single-table-page table thead tr th {
    font: Bold 16px/24px "Meta Pro" !important;
}

.table-rightborder {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.chart-Container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    width: 100%;
}

.btn-no-left-margin {
    margin-left: 0;
}

.font-bold {
    font-weight: 700 !important;
}

.font-bold-table .MuiInput-input {
    font-weight: 700 !important;
}

.table-layout-fix .MuiGrid-item {
    max-width: 100% !important;
}

.table-layout-fix table tbody tr td {
    overflow-wrap: anywhere;
}

.content-max-height {
    min-height: calc(100vh - 90px);
}

@media (max-width: 960px) {
    .content-max-height {
        min-height: calc(100vh - 103px);
    }
}

#thankyou-modal-headline {
    font-size: 1.25rem;
}

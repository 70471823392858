.breadcrumbs {
    padding-top: 9px;
    opacity: 1;
    padding-bottom: 8px;
}

.breadcrumbs li p {
    font-size: 14px;
    letter-spacing: 0px;
    color: #0091dc !important;
    opacity: 1;
}

.breadcrumbs li a {
    color: rgb(123, 132, 138);
    letter-spacing: 0px;
    opacity: 1;
    font-size: 14px;
}

.breadcrumbs li svg {
    width: 16px;
    height: 16px;
}

.breadcrumbs .last_breadcrumb {
    cursor: default;
    color: #333333 !important;
}

.container {
    background-color: #f2f3f5;
    height: calc(100vh - 43px);
    overflow-y: auto;
}

.container header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 60px;
    background-color: white;
    border-bottom: 2px solid #e0e0e0;
}

.container header .icon_fess {
    width: 48px;
    height: 48px;
}

.container header .icon_festo {
    width: 90px;
}

.container header .header_right {
    display: flex;
    align-items: center;
}

.container header .header_right button {
    margin-right: 36px;
    font-size: 16px;
}

.container header .header_right button svg {
    color: #0091dc;
}

.container #showcase_text {
    margin-top: 75px;
    user-select: none;
    text-align: center;
    animation: fade-text 2s forwards;
}

.container #showcase_text h1 {
    margin: 0 !important;
    font-size: 64px;
}

.container #showcase_text h2 {
    margin: 0 !important;
    font-size: 32px;
}

.container #showcase_image {
    display: flex;
    justify-content: center;
}

.container #showcase_image .shape_box {
    position: relative;
    top: 150px;
    background-image: url("./background.svg");
    background-repeat: no-repeat;
    background-position: center center;
    width: 800px;
    height: 550px;
    background-size: contain;
    animation: fade 2s forwards;
}

.container #showcase_image .shape_box .align {
    user-select: none;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.container #showcase_image .shape_box .align .shape_support {
    padding-bottom: 20px;
    width: 140px;
    height: 140px;
    animation: transform-up infinite 4s;
    animation-delay: 1.6s;
}

.container #showcase_image .shape_box .align .shape_wait {
    animation: spin infinite 2s;
    animation-delay: 1.6s;
}

.container #showcase_image .shape_box .align h4 {
    padding-top: 15px;
    color: white;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 40px;
}

.container footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-top: 2px solid rgb(224, 224, 224);
    padding: 12px 60px;
    font-size: 14px;
    color: #656565;
}

.container footer .right a {
    text-decoration: none;
    color: #656565;
    padding-left: 25px;
}

.container footer .right a:hover {
    color: rgb(0, 145, 220);
}

.container .active {
    display: block !important;
}

.container .rotate {
    transform: rotate(180deg);
    transform-origin: 75% 50%;
}

@keyframes spin {
    from {
        transform: rotate(180deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes transform-up {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(15px);
    }
    75% {
        transform: rotateY(335deg);
    }
    85% {
        transform: rotateY(-360deg);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes fade {
    from {
        transform: translateY(0);
        opacity: 0;
    }
    to {
        transform: translateY(-100px);
        opacity: 1;
    }
}

@keyframes fade-text {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 865px) {
    .container {
        height: calc(100vh - 80px);
    }
}

.TableHeadingContent button {
    transition: all 0.3s;
}
.TableHeadingContent button:hover {
    transform: translateY(-5px);
}
.TableHeadingContent .MuiGrid-root {
    width: unset !important;
}
.filter-popover {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: white;
    overflow: hidden;
}
.filter-popover .MuiGrid-item {
    padding: 5px;
}
.filter-popover .filter-popover-clear {
    border-top: 1px solid #e0e0e0;
}
.filter-popover .MuiAccordionDetails-root {
    flex-wrap: wrap;
    width: 300px;
    justify-content: space-between;
    max-height: 200px;
    overflow-y: auto;
}
.filter-popover .MuiAccordionDetails-root > div {
    display: flex;
    align-items: center;
}
.filter-popover .MuiAccordionDetails-root span {
    font-size: 12px;
}
.filter-popover .MuiFilledInput-root {
    background-color: #f2f3f5;
}
.filter-popover .MuiFormControl-root {
    width: 100%;
    border: none !important;
}
.filter-popover .MuiFormControl-root .MuiFilledInput-underline::before {
    border: none;
}
.filter-popover .MuiFormControl-root .MuiFilledInput-underline::after {
    border: none;
}
.popper_dialog_text {
    width: 135px;
}
.popper_dialog_text span {
    word-break: break-all;
    font-size: 13px !important;
}
.filter_chips {
    margin: 0 2px;
}
#simple-popover {
    z-index: 1300;
}

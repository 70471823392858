.fixed-header {
    position: sticky;
    top: 0;
    left: 0;
    height: 100%;
    background-color: white;
    z-index: 9;
    border-bottom: 2px solid #e0e0e0;
}

.fixed-header .alert {
    max-height: 100px;
    overflow: auto;
}

.fixed-header .main-header .fessnav-executorSelect {
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    width: 175px;
}

.fixed-header .main-header .fessnav-executorSelect .MuiInputBase-root {
    margin-top: 0px;
}

.fixed-header .main-header .fessnav-executorSelect .MuiInputBase-root:before {
    border-bottom: none;
}

.fixed-header .main-header .fessnav-executorSelect .MuiAutocomplete-endAdornment {
    margin-top: 3px;
}

.fixed-header .main-header .fessnav-executorSelect input {
    line-height: 1px !important;
}

.fixed-header .main-header .fessnav-logo .logoIMG {
    width: 48px;
    height: 48px;
}

.fixed-header .main-header .fessnav-content {
    height: 46px;
}

.fixed-header .main-header .fessnav-content .fessnav-language button {
    font: Normal 16px/24px Meta Pro !important;
}

.fixed-header .main-header .fessnav-content .fessnav-language button svg {
    color: #0091dc;
}

.fixed-header .main-header .fessnav-content .fessnav-language button:focus {
    outline: 0;
}

.fixed-header .main-header .fessnav-content .fessnav-user button:focus {
    outline: 0;
}

.fixed-header .main-header .fessnav-content .fessnav-user .User {
    font: Normal 16px/24px Meta Pro !important;
    text-transform: capitalize;
    letter-spacing: 0px;
    color: #333333;
}

.fixed-header .main-header .fessnav-content .fessnav-user .User .MuiButton-endIcon {
    margin-left: 0 !important;
}

.fixed-header .main-header .fessnav-content .fess-logo-wrapper a {
    display: flex;
    align-items: center;
}

.fixed-header .main-header .fessnav-content .fess-logo-wrapper .fess-logo {
    width: 90px;
}

.fess-logo-wrapper {
    max-height: 46px;
}

@media (max-width: 733px) {
    .fessnav-user {
        padding: 0 !important;
    }
}

@media (max-width: 557.95px) {
    .fessnav-logo {
        left: 0;
    }

    .fessnav-language {
        padding: 0 !important;
    }

    .fessnav-content {
        padding-right: 0 !important;
    }
}

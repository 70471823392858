.project-view .subsidiaryFestoName {
    font-weight: bold;
    font-style: italic;
    color: #0091dc;
    user-select: none;
    font-size: 13px;
}
.project-view .subsidiaryFestoName span {
    font-style: normal;
    font-weight: bold;
    color: #444;
}
.project-view .project-finished {
    height: 96px;
    padding: 8px;
    margin-top: 30px;
}
.project-view .project-finished .MuiGrid-container {
    height: 100%;
}
.project-view .project-finished p {
    font-size: 14px;
}
.project-view .projectMenu-secondtext-top-spacer {
    height: 40px;
}
@media (max-width: 960px) {
    .project-view .project-finished {
        height: 20px;
   }
    .project-view .projectMenu-secondtext-top-spacer {
        height: 20px;
   }
}
#btn_qrcode-generation {
    margin-left: 0 !important;
}
#btn_qrcode-search {
    margin-left: 0 !important;
}
